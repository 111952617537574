//
//  Device.js
//
//  Main component for rendering a single IoT device.  It renders all "system"
//  components for the device.  Each system represents either a controlled
//  appliance or the device itself.  Systems contain action/sensor components.
//
import React from 'react'
import { useState } from 'react';
import DevSystem from './DevSystem.js';

export default function Device({ devname, devstatus, dispname, lastactive, systems, devView, devver })
{
    var online = devstatus ? 'online' : 'offline';

    if(!devver) { devver = "unk"; }

    const [lastMsg, setLastMsg] = useState('none');

    if (systems)
    {
        const header    = devstatus ? 'jcard_header'  : 'jcard_header offline';
        const content   = devstatus ? 'jcard_content' : 'jcard_content offline';

        const d    = new Date(Date.parse(lastactive.substring(0,19) + 'Z'));
        const localtime = d.toLocaleString();

        return (
            <div className="jcard">
                <div className={header}>Device: {dispname}</div>
                <p className={content}>Status: {online}</p>
                <p className={content}>Last Active: {localtime}</p>
                <p className={content}>Last Update Message: {lastMsg}</p>
                <div className="controls">
                    {systems.map((sys, i) =>
                    {
                        return (!devView && sys.M.name.S === "device") ? '' :
                            (<DevSystem devname={devname} devstatus={devstatus}
                                key={devname + sys.M.name.S + i.toString()}
                                sysname={sys.M.name.S} sysnum={i+1} devver={devver}
                                actions={sys.M.actions} sensors={sys.M.sensors}
                                setLastMsg={setLastMsg} />);
                    })}
                </div>
            </div>
        );

    }
    else {
        return ('');
    }
}

