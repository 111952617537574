//
//  RestApi.js
//
//  This implements the UI to AWS backend for device visibility and control.
//  As of 02/10/2023, only 2 of the API URL and lambdas are used, and only one of
//  those is still invoked from within this code:
//      Function                URL                         Lambda
//      RestApiDeviceCommand -> restApiManageDeviceUrl  ->  iotRestManageDevice
//
//  The other URL: restApiListUrl is invoked from within Dashboard.js
//
import { useAws, debug } from '../settings.js';

const awsinfo     = require('../data/awsinfo.json');

//
//  This is the primary device control interface.
//  Take a device name and action, and send the action (i.e., the command)
//  to the AWS REST API, which in turn will send the command to the device
//  via websocket.
//
function RestApiDeviceCommand(dev, a, setLastMsg, signalDone)
{
    const awsinfo        = require('../data/awsinfo.json');
    const restApiPostUrl = awsinfo.restApiManageDeviceUrl;

    const apiToken = localStorage.getItem('apiToken') ?
        localStorage.getItem('apiToken') : '';

    const jsonData = { "devname" : dev, "devaction" : a }

    if(!useAws)
    {
        if(debug) {
            console.log("RestApi not using AWS");
            console.log("  dev: " + dev + " a: " + a);
        }
        jsonData.message = "ok";

        if(signalDone)
            { signalDone(); }

        return jsonData.message;
    }

    fetch(restApiPostUrl, {
        method: 'POST',
        withCredentials: true,
//          credentials: 'include',
        headers: { "Authorization": 'bearer ' + apiToken, },
        body: JSON.stringify(jsonData)
    })
    .then(response => {
        if(signalDone) { signalDone(); }
        if (!response.ok) {
            if(debug) { console.log('POST failed with status: ' + response.statusCode); }
            return Promise.reject(response);
        };
        return response.json()
    })
    .then(jsonData => {
        if(signalDone) { signalDone(); }
        if(debug) { console.log('success: ' + JSON.stringify(jsonData)); }
        setLastMsg(jsonData.message);
    })  // success
    .catch(error => {
        if(signalDone) { signalDone(); }
        if (typeof error.json === "function")
        {
            error.json().then(jsonError => {
                if(debug) {
                    console.log("Json error from API");
                    console.log(jsonError);
                }
            })
            .catch(genericError => {
                if(debug) {
                    console.log("Generic error from API");
                    console.log(genericError.statusText);
                }
            });
        } else {
            if(debug) {
                console.log("API POST fetch error");
                console.log(error);
            }
        }
    });

    return jsonData.message;
}


//
//  This interface allows the UI to upload a new schedule to the backend.
//
function RestApiPutSchedule(devname, sysname, aid, dotws, setLastMsg, signalDone)
{
    const scheduleUrl = awsinfo.restApiScheduleUrl;

    const apiToken = localStorage.getItem('apiToken') ?
        localStorage.getItem('apiToken') : '';

    // Get the timezone of the client (the browser) and use that as the default
    // timezone of the device.  UI should be updated to allow specification of
    // the timezone for each device.
    // getTimezoneOffset() returns the MINUTES to add to local time to get UTC.
    // Example: TZ is CDT (-0500) tzoffset will be +300
    const tzo = new Date().getTimezoneOffset();
    const tzoffset = tzo.toString();

    const jsonData = { "devname": devname, "sysname": sysname,
                       "aid": aid, "tzoffset": tzoffset, "days": dotws };
    if(!useAws)
    {
        if(debug) {
            console.log("RestApi not using AWS");
            console.log("  devname: " + devname + " sysname: " + sysname);
        }
        jsonData.message = "ok";

        if(signalDone)
            { signalDone(); }

        return jsonData;
    }

    fetch(scheduleUrl, {
        method: 'POST',
        withCredentials: true,
//          credentials: 'include',
        headers: { "Authorization": 'bearer ' + apiToken, },
        body: JSON.stringify(jsonData)
        //body: jsonData,
    })
    .then(response => {
        if(signalDone) { signalDone(); }
        if (!response.ok) {
            if(debug) { console.log('POST failed with status: ' + response.statusCode); }
            return Promise.reject(response);
        };
        return response.json()
    })
    .then(jsonData => {
        if(signalDone) { signalDone(); }
        if(debug) { console.log('success: ' + JSON.stringify(jsonData)); }
        setLastMsg(jsonData.message);
    })  // success
    .catch(error => {
        if(signalDone) { signalDone(); }
        if (typeof error.json === "function")
        {
            error.json().then(jsonError => {
                jsonData.message = jsonError;
                if(debug) {
                    console.log("Json error from API");
                    console.log(jsonError);
                }
            })
            .catch(genericError => {
                jsonData.message = genericError.statusText;
                if(debug) {
                    console.log("Generic error from API");
                    console.log(genericError.statusText);
                }
            });
        } else {
            jsonData.message = 'unknown fetch error';
            if(debug) {
                console.log("API POST fetch error");
                console.log(error);
            }
        }
    });

    return jsonData;
}

export { RestApiDeviceCommand, RestApiPutSchedule };
