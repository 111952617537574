//
//  DevSystem.js (formerly Device.js)
//
//  Main component for rendering a system within a single device.  It renders all "action"
//  components, and allows them to be invoked by the user, and also, all "sensor"
//  components, which are just passively displayed.
//
import React from 'react'
import { useState } from 'react';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';

import OnOff from './OnOff.js';
import PwrLed from './PwrLed.js';
import JCounter from './JCounter.js';
import JInfo from './JInfo.js';
import JEcho from './JEcho.js';
import PushButton from './PushButton.js';
import Weekly from './Weekly.js';
import { RestApiDeviceCommand } from './RestApi.js';

export default function DevSystem({ devname, devstatus, sysname, sysnum, actions, sensors, setLastMsg, devver })
{
    const [ devinfo, setDevInfo ]       = useState( { aname: "unk", aid: "unk" } );
    const [ weeklyOpen, setWeeklyOpen ] = useState(false);

    const openWeekly = (devname, sysname, aname, aid) =>
    {
        setDevInfo( { aname: aname, aid: aid } );
        /* console.log('openWeekly() dev: ' + devname + ' sys: ' + sysname
                    + ' aname: ' + devinfo.aname + ' aid: ' + devinfo.aid); */
        setWeeklyOpen(true);
    }

    const getDevInfo  = () => { return devinfo; };
    const closeWeekly = () => { setWeeklyOpen(false); }

    if(!devver) { devver = "unk"; }

    if (actions.hasOwnProperty('L'))
    {
        // Info for action components
        const devcmds   = actions.L.filter(a => a.M.type.S === "devcmd");
        const pbs       = actions.L.filter(a => a.M.type.S === "push");
        const onoffs    = actions.L.filter(a => (a.M.type.S === "onoff") || (a.M.type.S === "cyw43"));
        const echos     = actions.L.filter(a => a.M.type.S === "echo");

        // Info for status widgets
        const pwrleds   = sensors.L && sensors.L.filter(a => (a.M.type.S === "onoff"));
        const scalars   = sensors.L && sensors.L.filter(a => (a.M.type.S === "scalar"));
        const pcts      = sensors.L && sensors.L.filter(a => (a.M.type.S === "scalarpct"));
        const infos     = sensors.L && sensors.L.filter(a => (a.M.type.S === "info"));

        const btnColor  = devstatus ? '#1580f2' : '#afafaf';
        const btnBorder = devstatus ? '#1580f2' : '#afafaf';
        const content   = devstatus ? 'jcard_content_top' : 'jcard_content offline';

        // disable modal warning: ariaHideApp={false}
        // This didn't work to get rid of the Modal warning:
        //    <Modal isOpen={weeklyOpen} className="modal" appElement={document.getElementById('app')}>

        return (
            <div className="jcard">
                    {sysname === "device" ?
                        (<div className={content}>IoT Device Mgmt:&nbsp;{devname}&nbsp; ({devver})</div>) :
                        (<div className={content}>Appliance {sysnum}:&nbsp;&nbsp;{sysname}</div>) }

                    {pbs && (pbs.length > 0) &&
                        <>
                        <div className="jgridrow" key={"pbdiv1"}>
                            <Modal isOpen={weeklyOpen} ariaHideApp={false}
                                key={'pbmodal' + devname}>
                                <Weekly devname={devname} sysname={sysname}
                                    key={'pbweekly' + devname} setLastMsg={setLastMsg}
                                    getDevInfo={getDevInfo} closeWeekly={closeWeekly}/>
                            </Modal>
                            {pbs.map((a, i) =>
                                (<PushButton pushLabel={a.M.name.S} disabled={!devstatus}
                                    key={'pbbtn' + devname + a.M.command.S + i.toString()}
                                    devname={devname} cmd={a.M.command.S}
                                    pin={a.M.pin ? a.M.pin.S : 'none'} setLastMsg={setLastMsg} />
                                ))
                            }
                            {pwrleds.map((a, i) =>
                                (<PwrLed pwrLabel={a.M.name.S} disabled={!devstatus}
                                    key={'ledlbl' + devname + 'pwrled' + a.M.type.S + i.toString()}
                                    ledvalue={a.M.value.S} />))
                            }
                        </div>
                        <div className="jgridbottomrow" key={"pbdiv2"}>
                            {pbs.map((a, i) =>
                                (<div key={"pbrow"+i}>

                                <Button variant="primary" className="ms-1 me-1 mb-1"
                                    disabled={!devstatus}
                                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                                    onClick={() => openWeekly(devname, sysname, a.M.name.S, a.M.id.S)}
                                    key={'pbmodbtn' + devname + a.M.command.S + i.toString()}>
                                        {'Schedule ' + a.M.name.S}
                                </Button>
                                </div>))
                            }
                        </div>
                        </>
                    }

                    {echos.map((a, i) =>
                        (<JEcho echoLabel={a.M.name.S}
                            key={devname + 'echo' + a.M.command.S + i.toString()}
                            devname={devname} cmd={a.M.command.S}
                            setLastMsg={setLastMsg} />))
                    }

                    <div className="jgrid">
                    {scalars.map((a, i) =>
                        (<JCounter counterLabel={a.M.name.S} disabled={!devstatus}
                            key={devname + 'scalar' + a.M.type.S + i.toString()}
                            counterValue={a.M.value.S} />))
                    }

                    {pcts.map((a, i) =>
                        (<JCounter counterLabel={a.M.name.S} disabled={!devstatus}
                            key={devname + 'pct' + a.M.type.S + i.toString()}
                            counterValue={a.M.value.S} />))
                    }
                    </div>

                    {infos.map((a, i) =>
                        (<JInfo infoLabel={a.M.name.S} disabled={!devstatus}
                            key={devname + 'info' + a.M.type.S + i.toString()}
                            infoValue={a.M.value.S} />))
                    }

                    {onoffs && (onoffs.length > 0) &&
                        <>
                        <div className="jgridrow" key={"onoffdiv"}>
                            <Modal isOpen={weeklyOpen} ariaHideApp={false}
                                key={'onoffmodal' + devname}>
                                <Weekly devname={devname} sysname={sysname}
                                    key={'onoffweekly' + devname} setLastMsg={setLastMsg}
                                    getDevInfo={getDevInfo} closeWeekly={closeWeekly}/>
                            </Modal>
                            {onoffs.map( (a, i) => 
                                (<div key={"onoffdiv" + i}>
                                    <OnOff onoffLabel={a.M.name.S} disabled={!devstatus}
                                        key={'oolbl' + devname + a.M.command.S + i.toString}
                                        devname={devname} cmd={a.M.command.S}
                                        pin={a.M.pin ? a.M.pin.S : 'none'} setLastMsg={setLastMsg}
                                    />
                                    <Button variant="primary" className="ms-1 me-1 mb-1"
                                        disabled={!devstatus}
                                        style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                                        onClick={() => openWeekly(devname, sysname, a.M.name.S, a.M.id.S)}
                                        key={'oobtn' + devname + a.M.command.S + i.toString()}>
                                        {'Schedule ' + a.M.name.S}
                                    </Button>
                                </div>))
                            }
                        </div>
                        </>
                    }
                    {devcmds.map((a, i) =>
                    (<Button variant="primary" className="ms-1 me-1 mb-1"
                        disabled={!devstatus}
                        style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                        onClick={() => RestApiDeviceCommand(devname, a.M.command.S, setLastMsg)}
                        key={devname + a.M.command.S + i.toString()}>
                        {a.M.name.S}
                    </Button>))
                    }
            </div>
        );
    }
    else {
        return ('');
    }
}

