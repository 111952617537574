import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './jecho.css';
import { RestApiDeviceCommand } from './RestApi.js';
import { debug } from '../settings.js';

const JEcho = (props) =>
{
    const [msg, setMsg] = useState('');
    const { echoLabel, devname, cmd, setLastMsg } = props;

    const sendEcho = (e) =>
    {
        e.preventDefault();
        if(debug)
        {
            if(msg.length > 0)
                console.log('sending echo request for ' + msg);
            else
                console.log('not sending zero-length echo request');
            console.log('devname: ' + devname + ' cmd: ' + cmd + ' msg: ' + msg);
            //setLastMsg(msg);
        }
        RestApiDeviceCommand(devname, cmd + ' ' + msg, setLastMsg);
        setMsg('');
    }

    return (
        <div>
            <form onSubmit={(e) => sendEcho(e)}>
                <label className="ms-2">{echoLabel}
                    <input type="text" className="jecho" value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                        />
                    <input type="submit" value="Send" onSubmit={(e) => sendEcho(e)} />
                </label>
            </form>
        </div>
    )
}

JEcho.propTypes =
{
    echoLabel   : PropTypes.string,
    devname     : PropTypes.string,
    cmd         : PropTypes.string,
    setLastMsg  : PropTypes.func,
};

export default JEcho;
