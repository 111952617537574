import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './pushbutton.css';
import { RestApiDeviceCommand } from './RestApi.js';

const PushButton = (props) =>
{
    const [pushed, setPushed] = useState(false);
    const { disabled, pushLabel,
            devname, cmd, pin, setLastMsg, onChange } = props;

    const clearPushed = () => { setPushed(false); }

    const triggerPush = () =>
    {
        setPushed(true);
        RestApiDeviceCommand(devname, cmd + ' ' + pin + ' push', setLastMsg, clearPushed);

        if (typeof onChange === 'function') { onChange(!pushed); }
    }

    return (
        <div>
            <label className="ms-2 me-2">{pushLabel}</label>
            <div onClick={triggerPush}
                className={"pushbutton "
                    + (pushLabel.includes("Power") ? " power " : " reset ")
                    + (disabled ? " pbdisabled" : "")
                    + (pushed   ? " spinning"   : "")}
                />
        </div>
    )
}

PushButton.propTypes =
{
    disabled    : PropTypes.bool,
    pushLabel   : PropTypes.string,
    devname     : PropTypes.string,
    cmd         : PropTypes.string,
    pin         : PropTypes.string,
    setLastMsg  : PropTypes.func,
    onChange    : PropTypes.func,
};

export default PushButton;
