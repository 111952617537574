//
//  settings.js - global project constants
//

// Global system settings
export const useAws        = true;
export const debug         = false;

// Settings for scheduling components
export const maxperiods    = 2;
export const INC_MINUTES   = 15;
export const INCS_PER_HOUR = 4;
export const MAX_HOURS     = 24;
export const MAX_INCS      = MAX_HOURS * INCS_PER_HOUR;

export const counter2str = (counter) =>
{
    var incs  = parseInt(counter);

    if(incs < 0) incs = 0;
    if(incs > MAX_INCS) incs = MAX_INCS;

    const hours = parseInt(incs / INCS_PER_HOUR);
    const mins  = parseInt( (incs % INCS_PER_HOUR) * INC_MINUTES );

    return hours.toString().padStart(2, '0')
           + ':'
           + mins.toString().padStart(2, '0');
};


