//
//  App.js  - main entry point for React app
//

// React, AWS, bootstrap imports
import React, { useState } from 'react'
import '@aws-amplify/ui-react/styles.css';
import { Auth } from '@aws-amplify/auth';
import { withAuthenticator, } from '@aws-amplify/ui-react';
import { Button } from 'react-bootstrap';

// Local imports
import Dashboard from './components/Dashboard.js'
import ConnLog from './components/ConnLog.js'
import './components/jcards.css';

const App = ({ signOut }) =>
{
    const btnColor  = '#1580f2';
    const btnBorder = '#1580f2';

    const [apiToken, setApiToken] = useState('');
    const [logView,  setLogView]  = useState(false);
    const [devView,  setDevView]  = useState(false);

    const saveApiToken = () => { localStorage.setItem('apiToken', apiToken); window.location.reload(); };
    const clearApiToken = () => { localStorage.removeItem('apiToken', apiToken); window.location.reload(); };

    const showLogs      = () => { setLogView(true); }
    const showDashboard = () => { setLogView(false); }
    const showDevice    = () => { setDevView(true); }
    const hideDevice    = () => { setDevView(false); }

    Auth.currentSession().then( (res) => {
        const acctok = res.getAccessToken();
        //let jwt = accessToken.getJwtToken();
        //console.log(`acctok: ${JSON.stringify(acctok)}`)
        return acctok;
    });

    console.log('App.js ...');

    return (
        <div className="App jbackground_image">
            {logView && (
                <ConnLog showDashboard={showDashboard}/>
            )}

            {!logView && (
                <div> <Dashboard devView={devView}/> </div>)}

            {!logView &&
                (<div className="jbtnrow">
                    {localStorage.getItem('apiToken') ?
                        (<>
                            API Token Is Set
                            <Button variant="primary" className="btn-primary ms-2 me-2 mb-2"
                                style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                                key='clrapitoken'
                                onClick={clearApiToken}>Clear API Token</Button>
                            <Button variant="primary" className="me-2 mb-2"
                                style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                                key='logoutbtn'
                                onClick={signOut}>Log Out</Button>

                            <div className="jbtnsmallrow">
                                <Button variant="primary" className="mb-2 mt-2"
                                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                                    key='showlogs'
                                    onClick={showLogs}>Check Logs</Button>
                                <Button variant="primary" className="ms-2 mb-2 mt-2"
                                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                                    key='showdev'
                                    onClick={devView ? hideDevice : showDevice}>
                                        {devView ? "Hide" : "Show"} Device Info
                                </Button>
                            </div>
                        </>)
                        :
                        (<>
                            <input placeholder="API Token" value={apiToken}
                            onChange={(e) => setApiToken(e.target.value)} />
                            <Button variant="primary" className="btn-primary ms-2 me-2 mb-2"
                                style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                                key='setapitoken'
                                onClick={saveApiToken}>Set API Token</Button>
                        </>
                        )

                    }
                </div>)
            }
        </div>
    );
}

export default withAuthenticator(App);
