//
//  ConnLog.js component   - connection log table view
//
import React from 'react';
import { useState } from 'react';
import './jtable.css';

export default function ConnLog( { headings, data, rowindex } )
{
    return (
        <tr>
            {headings.map( (hdr, hidx) =>
            {
                return hdr.visible && <td>{data[hdr.idx] ? data[hdr.idx].S : 'n/a'}</td>
            })}
        </tr>
    );
}

