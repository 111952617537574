//
//  Dashboard.js component   - main dashboard view
//
//  Invoke the restApiListUrl on AWS backend, which in turn reads from DynamoDB
//  and render the results.
//
import React from 'react';
import { useState, useEffect } from 'react';
import Device from './Device.js'
import { useAws, debug } from '../settings.js';

export default function Dashboard( { devView } )
{
    const awsinfo       = require('../data/awsinfo.json')
    const devinfo       = require('../data/devices.json')
    const devListUrl    = awsinfo.restApiListUrl;

    const [devices, setDevices] = useState([]);

    async function loadDashboard(token, debug)
    {
        if(debug) { console.log("fetching data from AWS"); }

        fetch(devListUrl, {
            method: 'GET',
            headers: { "Authorization": 'bearer ' + token }
        })
        .then(response => {
                if (!response.ok) {
                    if(debug) { console.log('GET failed with status: ' + response.statusCode); }
                    return Promise.reject(response);
                };
                return response.json()
            })
        .then(data => { setDevices(data.Items); })  // success
        .catch(error => {
            if (typeof error.json === "function")
            {
                error.json().then(jsonError => {
                    if(debug) {
                        console.log("Json error from API");
                        console.log(jsonError);
                    }
                })
                .catch(genericError => {
                    if(debug) {
                        console.log("Generic error from API");
                        console.log(error.statusText);
                    }
                });
            } else {
                if(debug) { console.log("API fetch error"); }
            }
        });

    }

    useEffect(() =>
    {
        const apiToken = localStorage.getItem('apiToken') ?
            localStorage.getItem('apiToken') : '';

        if(useAws)
        {
            loadDashboard(apiToken, debug);
            // setInterval() works but is blocked due to CORS
            //setInterval(loadDashboard, 30000);
            //setInterval((apiToken, debug) => loadDashboard(apiToken, debug), 30000);
        }
        else
        {
            if(debug) { console.log("fetching data from local file"); }
            setDevices(devinfo);
        }
    }, [ devinfo ]);
    //}, [loadDashboard, devinfo ]);
    //});
    //}, [devListUrl, devinfo ]);
    //}, [devListUrl, devinfo, loadDashboard]);

/*
    This is an option to check for presence of attributes:
                    devver={d.hasOwnProperty('version') ? d.version.S : ''}

    The 'loadDashboard' function makes the dependencies of useEffect Hook (at line
    76) change on every render. Move it inside the useEffect callback. Alternatively,
    wrap the definition of 'loadDashboard' in its own useCallback() Hook
        react-hooks/exhaustive-deps
 */

    return (
        <div className="jrow">
            {devices.map(d =>
                (<Device devname={d.devname.S} devstatus={d.devonline ? d.devonline.BOOL : false}
                    dispname={d.dispname ? d.dispname.S : 'n/a'}
                    lastactive={d.lasttime.S.substring(0, 20)}
                    systems={d.systems ? d.systems.L : []}
                    devver={d.version ? d.version.S : 'unk'}
                    devView={devView}
                    key={d.devname.S} />)
            )}
        </div>
    )
}

