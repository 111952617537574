//
//  DotwRow.js component   - connection log table view
//
import React from 'react';
import PMButton from './PMButton.js';
import * as settings from '../settings.js';

const dumpKeys = (obj) => { console.log('object keys: ' + Object.keys(obj)); };

function DotwCounter( { day, ri, ci, setDotwCounter } )
{
    const handleClick = (ri, ci, start, plus) =>
    {
        //console.log('handleClick ri: ' + ri + ' ci: ' + ci);
        setDotwCounter(ri, ci, start, plus);
    };

    const start = settings.counter2str(day.start);
    const stop  = settings.counter2str(day.stop);

    return (
        <>
            <div className="ms-3" style={{ width:"50px" }} key={'start' + ri + ci }>{start}</div>

            <PMButton disabled={false} plus={true}
                clickedMe={ (e, start, plus) => handleClick(ri, ci, start=true, plus=true, e) } />

            <PMButton disabled={false} plus={false}
                clickedMe={ (e, start, plus) => handleClick(ri, ci, start=true, plus=false, e) } />

            <div className="ms-3" style={{ width:"50px" }} key={'stop'  + ri + ci }>{stop}</div>
            <PMButton disabled={false} plus={true}
                clickedMe={ (e, start, plus) => handleClick(ri, ci, start=false, plus=true, e) } />

            <PMButton disabled={false} plus={false}
                clickedMe={ (e, start, plus) => handleClick(ri, ci, start=false, plus=false, e) } />

        </>
    );
}

export default function DotwRow( { rowdata, ri, setDotwCheckbox, setDotwCounter, allEnabled } )
{
    let bgcolor = "";
    let fontcolor = "";
    let cname = "jcontent";

    if(allEnabled && (ri != 7))
    {
        cname = "disabled";
        bgcolor = "lightgray";
        fontcolor = "gray";
    }
    else if(!rowdata.enabled)
    {
        bgcolor = "lightgray";
        fontcolor = "gray";
    }

    return (
        <div key={'rowdata' + ri} className="jgridtblrow"
            style={{background:bgcolor, color:fontcolor}}>
            <div>
                <input type="checkbox" checked={rowdata.enabled} className="form-check-input"
                    style={{ }}
                    id={'checkbox' + rowdata.dotw + ri}
                    onChange={ (e) => setDotwCheckbox(ri, e.target.checked) }
                />
            </div>
            <div style={{width:"50px"}} key={'rowdata.dotw' + ri}>{rowdata.dotw}</div>
            {rowdata.periods.map( (p, i) =>
            {
                return (<DotwCounter key={'dotwcounter' + i} day={p} ri={ri} ci={i}
                         setDotwCounter={setDotwCounter} />)} )
            }
        </div>
    );

}

