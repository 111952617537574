
import PropTypes from 'prop-types';
import './pwrled.css';
import './ledlights.css';

//import React, { useState } from 'react';
//const OnIcon = () => <i className='bi bi-brightness-high'/>;

const PwrLed = (props) =>
{
    const { pwrLabel, disabled, ledvalue } = props;

    let pwrclass = "pwrled blueon";
    if(disabled || (ledvalue === "off")) { pwrclass = "pwrled ledoff"; }
    return (
        <div>
            <label className="ms-2 me-3">{pwrLabel}</label>
            <div className={pwrclass}/>
        </div>
    )
}

PwrLed.propTypes =
{
    pwrLabel        : PropTypes.string,
    disabled        : PropTypes.bool,
    ledvalue        : PropTypes.string,

    icons : PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            checked   : PropTypes.node,
            unchecked : PropTypes.node
        })
    ])
};

export default PwrLed;

