
import PropTypes from 'prop-types';
import './jcounter.css';

const JCounter = (props) =>
{
    const { counterLabel, disabled, counterValue } = props;

    let counterClass = "jcounter";
    if(disabled) { counterClass = "jcounter counteroff"; }
    return (
        <div>
            <label className="ms-1 me-2 mb-1">{counterLabel}</label>
            {!disabled ? (<div className={counterClass}>{counterValue}</div>) : ''}
        </div>
    )
}

JCounter.propTypes =
{
    counterLabel    : PropTypes.string,
    disabled        : PropTypes.bool,
    counterValue        : PropTypes.string,
//  onChange        : PropTypes.func,
//  className       : PropTypes.string,

    icons : PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            checked   : PropTypes.node,
            unchecked : PropTypes.node
        })
    ])
};

export default JCounter;

