
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './onoff.css';
import { RestApiDeviceCommand } from './RestApi.js';

//const OffIcon = () => <>🌜</>; -- worked
//const OnIcon = () => <>🌞</>; -- worked
//const OffIcon = () => <>'Off'</>; -- worked
//const OnIcon = () => <>'On'</>;
//const OffIcon = () => <i className='bi bi-brightness-low'/>;
const OnIcon = () => <i className='bi bi-brightness-high'/>;

<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightbulb-off" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.23 4.35A6.004 6.004 0 0 0 2 6c0 1.691.7 3.22 1.826 4.31.203.196.359.4.453.619l.762 1.769A.5.5 0 0 0 5.5 13a.5.5 0 0 0 0 1 .5.5 0 0 0 0 1l.224.447a1 1 0 0 0 .894.553h2.764a1 1 0 0 0 .894-.553L10.5 15a.5.5 0 0 0 0-1 .5.5 0 0 0 0-1 .5.5 0 0 0 .288-.091L9.878 12H5.83l-.632-1.467a2.954 2.954 0 0 0-.676-.941 4.984 4.984 0 0 1-1.455-4.405l-.837-.836zm1.588-2.653.708.707a5 5 0 0 1 7.07 7.07l.707.707a6 6 0 0 0-8.484-8.484zm-2.172-.051a.5.5 0 0 1 .708 0l12 12a.5.5 0 0 1-.708.708l-12-12a.5.5 0 0 1 0-.708z"/>
</svg>

const OnOff = (props) =>
{
    const [onoff, setOnOff] = useState(false);
    const { disabled, defaultChecked, className, onoffLabel,
            devname, cmd, pin, setLastMsg, onChange } = props;

    const onoffClasses = classNames('wrg-onoff',
    {
        'wrg-onoff--checked': onoff,
        'wrg-onoff--disabled': disabled,
        'wrg-onoff-container-unchecked--disabled': disabled,
    }, className);

    const getIcon = (type) =>
    {
        const { icons } = props;
        if ( ! icons ) { return null; }

        return icons[type] === undefined ?
            OnOff.defaultProps.icons[type] : icons[type];
    }

    const triggerOnOff = () =>
    {
        if (disabled) { return; }

        // 'onoff' polarity is reversed somehow
        RestApiDeviceCommand(devname,
                             cmd + ' ' + (pin ? pin : '') + (onoff ? ' off' : ' on'),
                             setLastMsg);
        setOnOff(!onoff);

        if (typeof onChange === 'function') { onChange(!onoff); }
    }

    useEffect(() => { if (defaultChecked) { setOnOff(defaultChecked); } }, [defaultChecked]);

    //<div onChange={triggerToggle}  className="wrg-toggle">
    //<div onClick={triggerToggle} className={`wrg-toggle ${toggle ? 'wrg-toggle--checked' : ''}`}>

    return (
        <div>
            <label className="ms-2 me-2 mb-2">{onoffLabel}</label>
            <div onClick={triggerOnOff} className={onoffClasses}>
                <div className={ onoff ? "wrg-onoff-container-checked" : "wrg-onoff-container-unchecked" }>
                    <div className={ onoff ? "wrg-onoff-check" : "wrg-onoff-uncheck" }>
                        <span>{ getIcon( onoff ? 'checked' : 'unchecked' ) }</span>
                    </div>
                </div>
            <div className="wrg-onoff-circle"></div>
            <input className="wrg-onoff-input" type="checkbox" aria-label="On/Off Button" />
            </div>
        </div>
    )
}

OnOff.defaultProps =
{
    //icons: { checked: <OnIcon />, unchecked: <OffIcon /> }
    icons: { checked: <OnIcon /> }
};

OnOff.propTypes =
{
    disabled        : PropTypes.bool,
    defaultChecked  : PropTypes.bool,
    className       : PropTypes.string,
    onoffLabel      : PropTypes.string,
    devname         : PropTypes.string,
    cmd             : PropTypes.string,
    pin             : PropTypes.string,
    setLastMsg      : PropTypes.func,
    onChange        : PropTypes.func,

    icons : PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            checked   : PropTypes.node,
            unchecked : PropTypes.node
        })
    ])
};

export default OnOff;

/*
 * 
 * Found at: https://webomnizz.com/how-to-create-a-onoff-switch-button-in-react/
        #:~:text=How%20to%20Create%20a%20Toggle%2FSwitch%20Button%20in%20React,
        Properties%20...%205%20Using%20Toggle%20Button%20Component%20
 *
 */

