
import PropTypes from 'prop-types';
import './jinfo.css';

const JInfo = (props) =>
{
    const { infoLabel, disabled, infoValue } = props;

    let infoClass = "jinfo";
    if(disabled) { infoClass = "jinfo infooff"; }
    return (
        <div>
            <label className="jinfolabel ms-2 me-3 mb-2">{infoLabel}</label>
            {!disabled ? (<div className={infoClass}>{infoValue}</div>) : ''}
        </div>
    )
}

JInfo.propTypes =
{
    infoLabel   : PropTypes.string,
    disabled    : PropTypes.bool,
    infoValue   : PropTypes.string
};

export default JInfo;

