import React from 'react';
import PropTypes from 'prop-types';
import './pmbutton.css';

const PMButton = (props) =>
{
    const { disabled, plus, ri, ci, clickedMe } = props;

//  const downedMe = (e) => { /* console.log('mouse DOWN!!'); */ };
    const uppedMe =  (e) => { /* console.log('mouse UP!!'); */ };

    const cname = "pmbutton "
                    + ((plus) ? " plus" : " minus")
                    + ((disabled) ? " pmdisabled" : "");

//      <button key={"pmb " + ri + " " + ci} onClick={(e) => clickedMe(ri, ci)}

    return (
        <button key={"pmb " + ri + " " + ci}
            onMouseDown={ (e) => clickedMe(ri, ci)} onMouseUp={ (e) => uppedMe(ri, ci) }
            className={cname} />
    )
}

PMButton.propTypes =
{
    disabled    : PropTypes.bool,
    plus        : PropTypes.bool,
    ri          : PropTypes.number,
    ci          : PropTypes.number,
    clickedMe   : PropTypes.func,
};

export default PMButton;
