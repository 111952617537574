//
//  Weekly.js component   - render a schedule for weekly/daily events
//
import React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useAws, debug, MAX_INCS, maxperiods } from '../settings.js';
import DotwRow from './DotwRow.js';
import { RestApiPutSchedule } from './RestApi.js';

export default function Weekly( { devname, sysname, setLastMsg, getDevInfo, closeWeekly } )
{
    // Handy utility function
//  const dumpKeys = (obj) => { console.log('object keys: ' + Object.keys(obj)); };

    // 'schedule' is the schedule document for a single device
    const awsinfo       = require('../data/awsinfo.json')
    const schedule      = require('../data/dbschedules.json')
    const template      = require('../data/dbtemplate.json')
    const scheduleUrl   = awsinfo.restApiScheduleUrl;

    const btnColor  = '#1580f2';
    const btnBorder = '#1580f2';

    const [ checkChanged, setCheckChanged ] = useState(0);
    const [ dotws, setDotws ] = useState([]);

    // Transform a schedule document from AWS DB format to usable JSON.
    const loadDotws = (schedule) =>
    {
        const dotws = schedule.days.L.map( (d) =>
        {
            // Use Array.reduce() instead of Array.map() to limit
            // the number of periods rendered.  In the backend, the
            // the DB schema is fixed at 4 periods, regardless.

            // Note that reduce() needs an initial value (optional parameter)
            // to know that 'newarr' is an array and not something else at the beginning.
            // Not sure why it can be an optional parameter.
            // 'newarr' is a running total for reduce (mandatory).
            // 'p' is the current array element (the current value of the iterator - mandatory)
            // 'pi' is the index of the current array element (optional).
            // 'origarr' is the array from which the current object was taken (optional)
            //const newperiods = d.M.periods.L.reduce( (newarr, p, pi, origarr) =>
            const periods = d.M.periods.L.reduce( (newarr, p, pi) =>
            {
                if(pi < maxperiods)
                {
                    newarr.push( {  start:   p.M.start.S,
                                    stop:    p.M.stop.S, } );
                }
                return newarr;
            }, []);

            return { dotw: d.M.dotw.S, enabled: d.M.enabled.BOOL, periods: periods }
        });

        setDotws(dotws);
    };

    // save the updates to the schedule - generate JSON and send to backend DB.
    const saveSchedule = () =>
    {
        //const sched = { devname: devname, sysname: sysname, aid: devinfo.aid, days: dotws };
        //const postData = JSON.stringify(sched);
        //console.log('saveSchedule: dname=' + dname + ' sname=' + sname + ' aid=' + aid);
        //console.log('saveSchedule: devname=' + devname + ' sysname=' + sysname + ' devinfo.aid=' + devinfo.aid);
        const rsp = RestApiPutSchedule(devname, sysname, devinfo.aid, dotws, setLastMsg);
        if(debug) { console.log(rsp); }
        closeWeekly();
    }

    const cancelSchedule = () => { closeWeekly(); }

    const headings_template = [
        { name: '',             idx: 'dotw',    type: "string", width: "10px", },   // checkbox
        { name: 'Day',          idx: 'dotw',    type: "string", width: "108px", },
        { name: 'Start Time 1', idx: 'start1',  type: "number", width: "108px", },
        { name: 'Stop Time 1',  idx: 'stop1',   type: "number", width: "108px", },
        { name: 'Start Time 2', idx: 'start2',  type: "number", width: "108px", },
        { name: 'Stop Time 2',  idx: 'stop2',   type: "number", width: "108px", },
        { name: 'Start Time 3', idx: 'start3',  type: "number", width: "80px", },
        { name: 'Stop Time 3',  idx: 'stop3',   type: "number", width: "80px", },
        { name: 'Start Time 4', idx: 'start4',  type: "number", width: "80px", },
        { name: 'Stop Time 4',  idx: 'stop4',   type: "number", width: "80px", },
    ];

    // newarr is the "running total" returned from each iteration of the loop.
    const headings = headings_template.reduce( (newarr, hdr, hidx) =>
    {
        // Double the maxperiods so that both start and stop headers are included.
        // Add 2 for the checkbox and 'Day' fields.
        if(hidx < ((maxperiods * 2) + 2)) newarr.push( hdr );
        return newarr;
    }, []); // newarr initialized to []

    const setDotwCheckbox = (daynum, enabled) =>
    {
        let tempDotws = dotws;
        tempDotws[daynum].enabled = enabled;
        setDotws(tempDotws);
        setCheckChanged(checkChanged + 1);
    };

    const setDotwCounter = (ri, ci, start, plus) =>
    {
        let startTime = parseInt(dotws[ri].periods[ci].start);
        let stopTime  = parseInt(dotws[ri].periods[ci].stop);

        if(start)
        {
            if(plus) startTime++; else startTime--;
            if(startTime > MAX_INCS) startTime = MAX_INCS;
            if(startTime < 0) startTime = 0;
            if(stopTime < startTime)
                stopTime = startTime;
        }
        else
        {
            if(plus) stopTime++; else stopTime--;
            if(stopTime > MAX_INCS) stopTime = MAX_INCS;
            if(stopTime < 0) stopTime = 0;
            if(startTime > stopTime)
                startTime = stopTime;
        }

        dotws[ri].periods[ci].start   = parseInt(startTime).toString();
        dotws[ri].periods[ci].stop    = parseInt(stopTime).toString();

        // Enable the schedule for this day.
        dotws[ri].enabled = true;

        setDotws(dotws);
        setCheckChanged(checkChanged + 1);
    };

    const devinfo = getDevInfo();

    async function fetchSchedule(token, debug)
    {
        if(debug) { console.log("fetching schedule data from AWS"); }

        const keyParts = [ devname, sysname, devinfo.aid ];
        const schedKey = keyParts.join('.');

        //fetch((scheduleUrl + '/' + devname), {
        fetch((scheduleUrl + '/' + schedKey), {
            method: 'GET',
            headers: { "Authorization": 'bearer ' + token }
        })
        .then(response => {
                if (!response.ok) {
                    if(debug) { console.log('GET failed with status: ' + response.statusCode); }
                    return Promise.reject(response);
                };
                return response.json()
            })
        .then(data => {
            loadDotws(data);
        })  // success
        .catch(error => {
            if (typeof error.json === "function")
            {
                error.json().then(jsonError => {
                    if(debug) {
                        console.log("Json error from API");
                        console.log(jsonError);
                    }
                })
                .catch(genericError => {
                    if(debug) {
                        console.log("Generic error from API");
                        console.log(error.statusText);
                    }
                });
            } else {
                if(debug) { console.log("API fetch error"); }
            }
            console.log("loading dotws with template");
            loadDotws(template);
        });
    }

    // useEffect() should re-render when a checkbox is changed in the
    // Weekly schedule component.
    useEffect(() =>
    {
        const apiToken = localStorage.getItem('apiToken') ?
            localStorage.getItem('apiToken') : '';

        // Fetch or load the schedule document for a single device.
        if(useAws)
        {
            if(checkChanged === 0)
            {
                fetchSchedule(apiToken, debug);
            }
        }
        else
        {
            if(checkChanged === 0)
            {
                console.log("using schedule from local file");
                loadDotws(schedule);

                /* const dotws = schedule.days.L.map( (d) =>
                {
                    // Use Array.reduce() instead of Array.map() to limit
                    // the number of periods rendered.  In the backend, the
                    // the DB schema is fixed at 4 periods, regardless.

                    // Note that reduce() needs an initial value (optional parameter)
                    // to know that 'newarr' is an array and not something else at the beginning.
                    // Not sure why it can be an optional parameter.
                    // 'newarr' is a running total for reduce (mandatory).
                    // 'p' is the current array element (the current value of the iterator - mandatory)
                    // 'pi' is the index of the current array element (optional).
                    // 'origarr' is the array from which the current object was taken (optional)
                    //const newperiods = d.M.periods.L.reduce( (newarr, p, pi, origarr) =>
                    const periods = d.M.periods.L.reduce( (newarr, p, pi) =>
                    {
                        if(pi < maxperiods)
                        {
                            newarr.push( {  start:   p.M.start.S,
                                            stop:    p.M.stop.S,
                                            enabled: p.M.enabled.BOOL, } );
                        }
                        return newarr;
                    }, []);

                    return { dotw: d.M.dotw.S, enabled: d.M.enabled.BOOL, periods: periods }
                });

                setDotws(dotws); */

            }
        }
    }, [ schedule.days.L, checkChanged ]);

    // If the "All" row is enabled, then the other rows must be disabled.
    const allEnabled = (checkChanged > 0) ? dotws[7].enabled : false;

    return (
        <div className="jcard">
            <div className={'jcard_header2'}>
                Device: {devname}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Appliance: {sysname}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Schedule: {devinfo.aname}</div>
            <div className="jgridtblrow">
                {headings.map( (heading, idx) => {
                    return (<div key={heading.name + idx}
                                style={{width:heading.width}}>{heading.name}</div>)
                })}
            </div>
            <div className="jcard">
                { dotws.map((row, rowindex) => {
                    return (<DotwRow key={'DotwRow' + rowindex}
                             rowdata={row} ri={rowindex}
                             setDotwCheckbox={setDotwCheckbox}
                             setDotwCounter={setDotwCounter}
                             allEnabled={allEnabled}/>)
                })}
            </div>

            <div className="jbtnleftrow">
                <Button variant="primary" className="ms-1 me-2 mb-2"
                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                    key='saveSchedule'
                    onClick={saveSchedule}>Save</Button>
                <Button variant="primary" className="ms-1 me-2 mb-2"
                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                    key='cancelSchedule'
                    onClick={cancelSchedule}>Cancel</Button>
            </div>



        </div>
    );

}

/*

                <tbody>
                    { dotws.map((row, rowindex) => {
                        return (<DotwRow key={'DotwRow' + rowindex}
                                 rowdata={row} rowindex={rowindex}
                                 setDotwCheckbox={setDotwCheckbox}
                                 allEnabled={allEnabled}/>)
                    })}
                </tbody>

                    const periods = d.M.periods.L.map( (p) =>
                    {
                        return { start: p.M.start.S, stop: p.M.stop.S, enabled: p.M.enabled.BOOL, }
                    });

    const [ validMins, setValidMins ] = useState(0);


                    {headings.map( (h, i) => {
                        return (
                            <div>
                                <label key={h.idx+'label'} className="ms-2"
                                    htmlFor={h.idx} style={{width:"100px"}}>{h.name}</label>
                                {h.dropdown ?
                                    (<Select key={h.idx+'select'} defaultValue={dotw}
                                        onChange={handleDotw} options={days} />) :
                                    (<input key={h.idx+'input'} className="jfield"
                                        type={h.type} style={{width:"80px"}} name={h.idx}
                                        step={15}
                                        value={validMins}
                                        onKeyDown={ (event) => { event.preventDefault(); } }
                                        onMouseDown={handleMouse}
                                        onChange={handleMinutes} />)}
                            </div>); })}


    const handleMinutes = (event) =>
    {
        const validVal = parseInt(event.target.value / 15) * 15;
        console.log('handleMinutes:' + validVal);
        setValidMins(validVal);
    };

    EXAMPLE reduce() code:

var options = [
  { name: 'One', assigned: true }, 
  { name: 'Two', assigned: false }, 
  { name: 'Three', assigned: true }, 
];

var reduced = options.reduce(function(filtered, option) {
  if (option.assigned) {
     var someNewValue = { name: option.name, newProperty: 'Foo' }
     filtered.push(someNewValue);
  }
  return filtered;
}, []);


*/
