//
//  ConnLog.js component   - connection log table view
//
import React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ConnRow from './ConnRow.js';
import PropTypes from 'prop-types';
import './jtable.css';
import { useAws, debug } from '../settings.js';
import { RestApiLoadConnLog } from './RestApi.js';
import Modal from 'react-modal';

const btnColor  = '#1580f2';
const btnBorder = '#1580f2';

// Create a simple class for a Modal
const PurgeForm = (props) =>
{
    const { month, day, setMonth, setDay, purgeLog, closePurgeForm } = props;

    const sendPurge = (e) =>
    {
        e.preventDefault();
        console.log('sending purge request month: ' + month + ' day: ' + day);
        setMonth(month);
        setDay(day);
        purgeLog();
        closePurgeForm();
    }

    return (
        <div className='jbtnrow'>
            <p className='jcard_content'>Select a day (dd) and month (mm) to purge records</p>
            <form onSubmit={(e) => sendPurge(e)}>
                <label className="ms-2">Purge Month
                    <input type="text" className="jecho" value={month} key='inmonth'
                        onChange={(e) => setMonth(e.target.value)}/>
                </label>
                <label className="ms-2">Purge Day
                    <input type="text" className="jecho" value={day} key='inday'
                        onChange={(e) => setDay(e.target.value)}/>
                </label>
                <div>
                    <Button variant="primary" className="ms-1 me-2 mb-2" key='spbutton'
                        style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                        onClick={sendPurge}>Send Purge</Button>
                    <Button variant="primary" className="ms-1 me-2 mb-2" key='cancelPurge'
                        style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                        onClick={closePurgeForm}>Cancel</Button>
                </div>
            </form>
        </div>
    );
};

PurgeForm.propTypes =
{
    month           : PropTypes.string,
    day             : PropTypes.string,
    setMonth        : PropTypes.func,
    setDay          : PropTypes.func,
    purgeLog        : PropTypes.func,
    closePurgeForm  : PropTypes.func,
};

export default function ConnLog( { showDashboard } )
{
    const awsinfo       = require('../data/awsinfo.json')
    const logrecs       = require('../data/logrecs.json')
    const connLogUrl    = awsinfo.restApiConnLogUrl;

    const [ loginfo,   setLogInfo   ] = useState([]);
    const [ purgeOpen, setPurgeOpen ] = useState(false);
    const [ month,     setMonth     ] = useState('');
    const [ day,       setDay       ] = useState('');

    const openPurgeForm = () =>
    {
        //setDevInfo( { aname: aname, aid: aid } );
        console.log('openPurgeForm()');
        setPurgeOpen(true);
    }

    const closePurgeForm = () => { setPurgeOpen(false); }

    const headings = [
        { name: 'ConnectionId',     idx: 'connectionId',    visible: true  },
        { name: 'CB URL',           idx: 'callbackUrl',     visible: false },
        { name: 'Dev Name',         idx: 'devname',         visible: true  },
        { name: 'Connected',        idx: 'connectTime',     visible: false  },
        { name: 'Registered',       idx: 'registerTime',    visible: false  },
        { name: 'Sensor Update',    idx: 'sensorTime',      visible: false  },
        { name: 'Disconnected',     idx: 'disconnectTime',  visible: false  },
        { name: 'Connected',        idx: 'lconn',           visible: true  },
        { name: 'Registered',       idx: 'lreg',            visible: true  },
        { name: 'Sensor Update',    idx: 'lupd',            visible: true  },
        { name: 'Disconnected',     idx: 'ldisc',           visible: true  },
    ];

    //const btnColor  = '#1580f2';
    //const btnBorder = '#1580f2';

    // Convert GMT received from backend into local time of the browser.
    function j2loc(tm)
    {
        const d = new Date(Date.parse(tm.substring(0,19) + 'Z'));
        if( (d instanceof Date) && (!isNaN(d.valueOf())) )
        {
            const hours = (d.getHours() > 12) ? (d.getHours() - 12) : d.getHours();
            const tfmt = [  d.getFullYear(), '-',
                            String(d.getMonth() + 1).padStart(2, '0'),  '-',
                            String(d.getDate()).padStart(2, '0'),       ' ',
                            String(hours).padStart(2, '0'),      ':',
                            String(d.getMinutes()).padStart(2, '0'),    ':',
                            String(d.getSeconds()).padStart(2, '0'),    ' ',
                            ((d.getHours() >= 12) ? 'PM' : 'AM') ];
            return tfmt.join('');
        }
        return 'none';
    }

    async function loadConnLog(token, debug)
    {
        if(debug) { console.log("fetching loginfo from AWS"); }

        fetch(connLogUrl, {
            method: 'GET',
            headers: { "Authorization": 'bearer ' + token }
        })
        .then(response =>
        {
                if (!response.ok) {
                    if(debug) { console.log('GET failed with status: ' + response.statusCode); }
                    return Promise.reject(response);
                };
                return response.json()
        })
        .then(data =>
        {
            const sortrecs = data.Items.sort((a, b) =>
                { return ( (a.connectTime && b.connectTime) ?
                                b.connectTime.S.localeCompare(a.connectTime.S) : false); });

            const localrecs = sortrecs.map( (rec) =>
            {
                const lconn = rec.connectTime ? j2loc(rec.connectTime.S) : 'none';
                const lreg  = rec.registerTime ? j2loc(rec.registerTime.S) : 'none';
                const lupd  = rec.sensorTime ? j2loc(rec.sensorTime.S) : 'none';
                const ldisc = rec.disconnectTime ? j2loc(rec.disconnectTime.S) : 'none';
                //return {...rec, lconn: lconn, lreg: lreg, lupd: lupd, ldisc: ldisc }
                return {...rec, lconn: { S: lconn},
                                lreg:  { S: lreg},
                                lupd:  { S: lupd},
                                ldisc: { S: ldisc} };
            });
            //setLogInfo(sortrecs);
            setLogInfo(localrecs);
        })  // success
        .catch(error => {
            if (typeof error.json === "function")
            {
                error.json().then(jsonError => {
                    if(debug) {
                        console.log("Json error from API");
                        console.log(jsonError);
                    }
                })
                .catch(genericError => {
                    if(debug) {
                        console.log("Generic error from API");
                        console.log(error.statusText);
                    }
                });
            } else {
                if(debug) { console.log("API fetch error"); }
            }
        });
    }

    async function purgeConnLog(token, debug)
    {
        const jsonData = { "day": day, "month" : month, "year" : "2023" }

        if(!useAws) { console.log("Use AWS disabled"); console.log(jsonData); return; }

        if(debug) { console.log("purging loginfo on AWS"); console.log(jsonData); }

        fetch(connLogUrl, {
            method: 'POST',
//          withCredentials: true,
            headers: { "Authorization": 'bearer ' + token },
            body: JSON.stringify(jsonData)
        })
        .then(response =>
        {
            if (!response.ok) {
                if(debug) { console.log('POST failed with status: ' + response.statusCode); }
                return Promise.reject(response);
            };
            return response.json()
        })
        .then(data =>
        {
            console.log("POST succeeded from API");
            console.log(data);
        })  // success
        .catch(error => {
            if (typeof error.json === "function")
            {
                error.json().then(jsonError => {
                    if(debug) {
                        console.log("Json error from API");
                        console.log(jsonError);
                    }
                })
                .catch(genericError => {
                    if(debug) {
                        console.log("Generic error from API");
                        console.log(error.statusText);
                    }
                });
            } else {
                if(debug) { console.log("API fetch error"); }
            }
        });
    }

    useEffect(() =>
    {
        const apiToken = localStorage.getItem('apiToken') ?
            localStorage.getItem('apiToken') : '';

        if(useAws)
        {
            loadConnLog(apiToken, debug);
        }
        else
        {
            if(debug) { console.log("fetching loginfo from local file"); }
            const sortrecs = logrecs.sort((a, b) => { return (b.connectTime.S.localeCompare(a.connectTime.S)); });
            setLogInfo(sortrecs);
        }
    }, [ logrecs ]);

/*
                <Button variant="primary" className="me-2 mb-2" key='PurgeLog'
                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                    onClick={purgeConnLog}>Purge Log
                </Button>
*/

    return (
        <div>
            <div className="jbtnrow">
                <Button variant="primary" className="mt-2 me-2 mb-2" key='ShowDashboard'
                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                    onClick={showDashboard}>Back to Dashboard</Button>
                <Button variant="primary" className="mt-2 me-2 mb-2" key='RefreshLog'
                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                    onClick={loadConnLog}>Refresh Log
                </Button>
                <Button variant="primary" className="mt-2 me-2 mb-2" key='pfbutton'
                    style={{ backgroundColor: btnColor, borderColor: btnBorder }}
                    onClick={openPurgeForm}>Purge Records
                </Button>
                <Modal isOpen={purgeOpen} ariaHideApp={false} key={'pfmodal'}>
                    <PurgeForm key={'pform'} month={month} setMonth={setMonth}
                        day={day} setDay={setDay}
                        closePurgeForm={closePurgeForm} purgeLog={purgeConnLog}/>
                </Modal>
            </div>
            <table className="jTableGrid">
                <thead>
                    <tr>
                        {headings.map(heading => {
                            return heading.visible && (<th key={heading.name}>{heading.name}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {(loginfo.length > 0) ? loginfo.map((row, index) => {
                        return (<ConnRow headings={headings} key={'ConnRow' + index} data={row} colindex={index} />)
                    }) : <tr><td></td></tr>}
                </tbody>
            </table>

        </div>
    );
}

